import moment from 'moment';

import { EFields, EZones } from 'enums';
import FieldsData from 'constants/FieldsData.json';
import { IFieldsData } from 'types';

export const FIELD_VALIDATION_TYPES = {
  STRING: 'string',
  STRING_AND_NUMBER: 'string_and_number',
  ONLY_STRING: 'only_string',
  DATE: 'date',
  GENDER: 'gender',
};

const validatedByRegex = (type: string, value: string, zone: EZones) => {
  switch (type) {
  case FIELD_VALIDATION_TYPES.STRING:
    if (zone === EZones.mrz) {
      return /^[0-9a-zA-Z]*$/.test(value);
    }
    // eslint-disable-next-line max-len, no-case-declarations
    return /^[ !%'()*+,-./0123456789:;<>ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿĀāĂăĄąĆćĈĉċČčĎďĐđĒēĖėĘęĚěĜĝĞğĠġĢģĤĥĩĪīĭĮįİıĴĵĻĽľŁłŃńŅņŇňŌōŏŐőŒœŘřŚśŜŝŞşŠšŢţŤťŨũŪūŬŭŮůŰűųŵŸŹźŻżŽžơǍǎǐǔȘșȚțḤḨḩ•\\]*$/g.test(value);
  case FIELD_VALIDATION_TYPES.DATE:
    return moment(value).isValid();
  case FIELD_VALIDATION_TYPES.STRING_AND_NUMBER:
    if (zone === EZones.mrz) {
      return /^[0-9a-zA-Z ]*$/.test(value);
    }
    return /(^[0-9a-zA-Z]{0,1}$)|(^[0-9a-zA-Z][0-9a-zA-Z-.]*([0-9a-zA-Z]{1,}$))/g.test(value);
  case FIELD_VALIDATION_TYPES.ONLY_STRING:
    if (zone === EZones.mrz) {
      return /^[a-zA-Z ]*$/.test(value);
    }
    // eslint-disable-next-line max-len, no-case-declarations
    return /^[ !%'()*+,-./0123456789:;<>ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýÿĀāĂăĄąĆćĈĉċČčĎďĐđĒēĖėĘęĚěĜĝĞğĠġĢģĤĥĩĪīĭĮįİıĴĵĻĽľŁłŃńŅņŇňŌōŏŐőŒœŘřŚśŜŝŞşŠšŢţŤťŨũŪūŬŭŮůŰűųŵŸŹźŻżŽžơǍǎǐǔȘșȚțḤḨḩ•\\]*$/g.test(value);
  case FIELD_VALIDATION_TYPES.GENDER:
    return /^m$|^f$|^-$/.test(value);
  default:
    return true;
  }
};

const fieldValueValidated = (
  field: string,
  zone: EZones,
  value: string,
  optional: boolean,
): boolean => {
  if (!value && optional) return true;
  const filedData = FieldsData.find((fd: IFieldsData) => fd.name === field)
    || { validationType: '', maxLength: 0 };
  const sizeAllowed = value.length <= filedData.maxLength;
  return sizeAllowed && validatedByRegex(filedData.validationType, value, zone);
};

const valuesIsSame = (value: string, targetValue: string, field = ''): boolean => {
  if (field === EFields.gender) {
    const maleMatching = [value, targetValue].every(value => {
      return ['m', 'male'].includes(value);
    });
    const femaleMatching = [value, targetValue].every(value => {
      return ['f', 'female'].includes(value);
    });
    return maleMatching || femaleMatching;
  }
  return value?.toLowerCase() === targetValue?.toLowerCase()
};

const isValidEmail = (email = '') => !!(email && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/));

const getPasswordRequirements = (password: string) => {
  return [
    {
      // Minimum length: 8
      message: 'set-password.message.option-1',
      valid: password && password.length >= 8,
    },
    {
      // Maximum length: 100
      message: 'set-password.message.option-2',
      valid: password && password.length <= 100,
    },
    {
      // Upper case: min 1
      message: 'set-password.message.option-3',
      valid: password && /[A-Z]/.test(password),
    },
    {
      // Lower case: min 1
      message: 'set-password.message.option-4',
      valid: password && /[a-z]/.test(password),
    },
    {
      // Digits: min 2
      message: 'set-password.message.option-5',
      valid: password && /(\D*\d\D*){2}/.test(password),
    },
    {
      // No spaces allowed
      message: 'set-password.message.option-6',
      valid: password && /^\S*$/.test(password),
    },
  ];
}

export {
  isValidEmail,
  valuesIsSame,
  fieldValueValidated,
  getPasswordRequirements
}